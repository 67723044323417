export const QUESTION_TYPES = {
    SINGLE_SELECT: 'single-select',
    MULTI_SELECT: 'multi-select',
    RANKED: 'ranked'
};

export const questions = [
    {
        id: 0,
        title: 'The pathophysiology of IgA nephropathy as an autoimmune disease is associated with elevated circulating levels of ______?',
        instructions: 'Select one option from below',
        instructionsOnSubmit: '',
        tag: 'Q1.',
        type: QUESTION_TYPES.SINGLE_SELECT,
        options: [
            'Gd-IgA1',
            'IgA',
            'BAFF',
            'IgM',
        ],
        answer: 'Gd-IgA1',
        showPercentageOnSubmit: false,
        info: {
            title: 'IgA Nephropathy Disease Mechanism¹',
            videoPath: 'videos/IgA_nephropathy_disease_mechanism.mp4',
            videoCaption: 'Rajasekaran A, Julian BA, Rizk DV. IgA nephropathy: an interesting autoimmune kidney disease. Am J Med Sci. 2021;361(2):176-194',
            qrCodePath: 'images/qr_q1.png',
            qrCodeText: '<p><strong>IgA Nephropathy Disease Mechanism</strong></p><br /><p>IgA nephrophathy, or IgAN, is an autoimmune disease that affects the kidneys, but may begin in the gut</p>'
        }
    },
    {
        id: 1,
        title: 'What level of proteinuria indicates risk for kidney function loss?',
        instructions: 'Select one option from below',
        instructionsOnSubmit: '',
        tag: 'Q2.',
        type: QUESTION_TYPES.SINGLE_SELECT,
        options: [
            '<0.5 g/d',
            '0.5 - 1 g/d',
            '>1.0 g/d',
            'All of the above',
        ],
        answer: 'All of the above',
        showPercentageOnSubmit: false,
        info: {
            title: 'RaDaR Study²',
            videoPath: 'videos/RaDaR_Booth.mp4',
            videoCaption: 'Pitcher, David; Braddon, Fiona; Hendry, Bruce; Mercer, Alex; Osmaston, Kate; Saleem, Moin A.; Steenkamp, Retha; Wong, Katie; Turner, A. Neil; Wang, Kaijun; Gale, Daniel P.; Barratt, Jonathan. Long-Term Outcomes in IgA Nephropathy. Clinical Journal of the American Society of Nephrology 18(6):p 727-738, June 2023. | DOI: 10.2215/CJN.0000000000000135',
            qrCodePath: 'images/qr_q2.png',
            qrCodeText: '<strong>Overview of RaDaR Registry and Implications for IgAN Management</strong>'
        }
    },
    {
        id: 2,
        title: 'Arrange the 4-Hit pathophysiological factors in the order they are known to contribute to IgAN?',
        instructions: 'Drag and drop',
        instructionsOnSubmit: '',
        tag: 'Q3.',
        type: QUESTION_TYPES.RANKED,
        options: [
            'IgG & IgA autoantibody formation',
            'Immune complex deposition',
            'IgA Immune complexes formation',
            'Elevated levels of Gd-IgA1',
        ],
        answer: ['Elevated levels of Gd-IgA1', 'IgG & IgA autoantibody formation', 'IgA Immune complexes formation', 'Immune complex deposition'],
        showPercentageOnSubmit: false,
        info: {
            title: 'The 4 Hits of IgA Nephropathy³',
            videoPath: 'videos/4_hits_if_IgA_nephropathy.mp4',
            videoCaption: 'Rajasekaran A, Julian BA, Rizk DV. IgA nephropathy: an interesting autoimmune kidney disease. Am J Med Sci. 2021;361(2):176-194',
            qrCodePath: 'images/qr_q3.png',
            qrCodeText: '<p>It is thought that the mechanism by which IgA nephropathy develops is through a series of 4 consecutive “hits” that ultimately lead to kidney damage.</p><br /><p>Learn more about the pathogenesis of IgAN through this animated video narrated by IgANexus faculty Professor Jonathan Barratt.</p>'
        }
    },
    {
        id: 3,
        title: 'Which of the following treatment options would you consider disease modifying in patients with IgAN?',
        instructions: 'Choose any or all options, and tap submit to see how your peers responded',
        instructionsOnSubmit: 'This is how your peers responded',
        tag: 'Q4.',
        type: QUESTION_TYPES.MULTI_SELECT,
        options: [
            'B-Cell Modulators',
            'Compliment Inhibitors',
            'Endothelin Receptor Antagonists',
            'RAS Inhibition',
            'SGLT2 Inhibitors',
            'Systemic Glucocorticoid Therapy',
            'TRF Budesonide',
        ],
        answer: null,
        showPercentageOnSubmit: true,
        // need clarity below on if video changes
        info: {
            title: 'Which of the following treatment options would you consider disease modifying in patients with IgAN?',
            videoPath: 'videos/Biomarkers_Booth.mp4',
            videoCaption: '',
            qrCodePath: 'images/qr_q4.png',
            qrCodeText: '<p></p>'
        }
    },
    {
        id: 4,
        title: 'What do you consider a realistic treatment goal for eGFR decline over time <br />for patients with IgA nephropathy?',
        instructions: 'Select one option from below',
        instructionsOnSubmit: '',
        tag: 'Q5.',
        type: QUESTION_TYPES.SINGLE_SELECT,
        options: [
            'Stable eGFR ',
            'eGFR decline < 1 mL/min/1.73m² per year',
            'eGFR decline < 3 mL/min/1.73m² per year',
            'eGFR decline over time not considered as a treatment goal',
        ],
        answer: '', 
        showPercentageOnSubmit: false,
        info: {
            title: 'What do you consider a realistic treatment goal for eGFR decline over time <br />for patients with IgA nephropathy?⁴',
            videoPath: 'videos/Galactose_deficient_IgA_Booth.mp4',
            videoCaption: 'Pitcher, David; Braddon, Fiona; Hendry, Bruce; Mercer, Alex; Osmaston, Kate; Saleem, Moin A.; Steenkamp, Retha; Wong, Katie; Turner, A. Neil; Wang, Kaijun; Gale, Daniel P.; Barratt, Jonathan. Long-Term Outcomes in IgA Nephropathy. Clinical Journal of the American Society of Nephrology 18(6):p 727-738, June 2023. | DOI: 10.2215/CJN.00000000000001351.',
            qrCodePath: 'images/qr_q5.png',
            qrCodeText: '<p><strong>What do you consider a realistic treatment goal for eGFR decline over time <br />for patients with IgA nephropathy?</strong></p>'
        }
    },
    {
        id: 5,
        title: 'In IgAN, what is the most prominent source of gd-IgA1?',
        instructions: 'Select one option from below',
        instructionsOnSubmit: '',
        tag: 'Q6.',
        type: QUESTION_TYPES.SINGLE_SELECT,
        options: [
            'Respiratory lymphoid tissue',
            'Bone marrow',
            'Gut-associated lymphoid tissue',
            'Kidneys',
        ],
        answer: 'Gut-associated lymphoid tissue',
        showPercentageOnSubmit: false,
        info: {
            title: 'Galactose-Deficient IgA Immune Complexes⁵',
            videoPath: 'videos/Galactose_deficient_IgA_Booth.mp4',
            videoCaption: 'Boyd JK, Cheung CK, Molyneux K, Feehally J, Barratt J. Kidney Int. 2012;81(9):833-843. 2. Kwon CS, Daniele P, Forsythe A, Ngai C. A systematic literature review of the epidemiology, health- related quality of life impact, and economic burden of immunoglobulin A nephropathy. J Health Econ Outcomes Res. 2021;8(2):36-45. 3. Rajasekaran A, Julian BA, Rizk DV. IgA nephropathy: an interesting autoimmune kidney disease. Am J Med Sci. 2021;361(2):176-194',
            qrCodePath: 'images/qr_q5.png',
            qrCodeText: '<p><strong>Galactose-Deficient IgA Immune Complexes<sup>4</sup></strong></p>'
        }
    },
];