<template>
    <div class="question-review-screen">
        <div class="question-review-screen__video-wrapper">
            <h1 class="question-review-screen__title">
                <span v-if="currentQuestion.tag" class="question-tag__wrapper"><span class="question-tag">{{ currentQuestion.tag }}</span></span>
                <span v-html="currentQuestion.info.title"></span>
            </h1>


            <VideoPlayer
                v-if="currentQuestion.info.videoPath && questionIndex !== '3' && questionIndex !== '4'"
                class="question-review-screen__video"
                :video-name="currentQuestion.info.videoPath"
                :is-video-muted="true"
                :show-video-timings="false"
                :restart-video-key="restartVideoKey"
            />

            <div v-else-if="questionIndex === '3'" class="question multi-select-receiver">
                <span
                    v-for="option in currentQuestion.options"
                    :key="option"
                    :class="[
                        'multi-select__item',
                        getMultiSelectPercentage(option)
                    ]"
                >{{ option }}</span>
            </div>

            <div v-else-if="questionIndex === '4'" class="image-container">
                <!-- two images side by side  -->
                <img src="@/assets/images/ASN-image1.png" alt="Image 1" class="image-item" />
                <img src="@/assets/images/ASN-image2.png" alt="Image 2" class="image-item" />
            </div>
            
  
            <p v-if="currentQuestion.info.videoCaption" class="question-review-screen__video-caption">
                {{ currentQuestion.info.videoCaption }}
            </p>
        </div>

        <div class="qrcode-wrapper">
            <div v-if="currentQuestion.info.qrCodeText" class="qrcode-text" v-html="currentQuestion.info.qrCodeText">
            </div>

            <div v-if="currentQuestion.info.qrCodePath" class="qrcode-container">
                <div class="qrcode-icon-container">
                    <img class="qrcode-icon" src="@/assets/images/phone-qr.svg" alt="phone qr icon" />
                </div>

                <span class="qrcode-pipe"></span>

                <div class="qrcode">
                    <p class="qrcode-call-to-action-text">
                        Visit the IgANexus Website to learn more
                    </p>
                    <img class="qrcode-image" :src="require(`@/assets/${currentQuestion.info.qrCodePath}`)" alt="QR Code" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { questions } from '@/questions';
import AnalyticsService from '@/services/analytics';

export default {
    name: 'QuestionReviewScreen',
    data() {
        return {
            percentages: {},
            answer: null,
            readonly: false,
        }
    },
    computed: {
        questionIndex() {
            return this.$route.params.questionIndex || 0;
        },
        currentQuestion() {
            return questions[this.questionIndex];
        },
        restartVideoKey() {
            return this.$route.query.restartVideoKey || '';
        },
        getMultiSelectPercentage() {
            return (option) => {
                return `multi-select__item--percentage-${this.percentages[option] || 0}`;
            }
        },
    },
    mounted() {
        const percentagesParam = this.$route.query.percentages;
        if (percentagesParam) {
            try {
                this.percentages = JSON.parse(percentagesParam);
            } catch (e) {
                console.error('Error parsing percentages:', e);
            }
        } else {
            this.percentages = AnalyticsService.getResponsesPercentage(this.currentQuestion.id);
        }
    }
}
</script>

<style lang="css" scoped>
.image-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.image-item {
    width: 49%;
    object-fit: cover;
}
</style>